.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-menu {
    display: none;
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 0.5rem 0;
    margin-top: 0.125rem;
    min-width: 10rem;
}

.dropdown-menu li {
    padding: 0.25rem 1rem;
}

.dropdown-menu li:hover {
    background-color: #f8f9fa;
}

:root {
   --bs-body-bg: #f2f3f4 !important;
  --bs-primary: #09af20 !important;
  --bs-primary-rgb: 9,175,32;
  --bs-primary-inv: #ff43f6 !important;
  --bs-primary-lighten: #ddc5f9 !important;

  --bs-secondary:#6C099B !important;
  --bs-secondary-inv: #6C099B !important;
  --bs-secondary-lighten: #f7c4a9 !important;

  --bs-danger:#B71C1C !important;
  --bs-info:#7251B5 !important;
  --bs-warning:#E65100 !important;
  --bs-success:#388E3C !important;
  --bs-nav-tabs-link-active-bg : #101003  !important;

  --bs-dark-rgb: 28,37,57; 
  --bs-light-rgb: 237, 243, 245;
  --bs-secondary-rgb : 14,75,33;
  
  //Primary Header
}

$enable-rounded:   false;
$font-size-base: 0.85rem;
$theme-colors: (
  primary: #09af20,
  secondary:#6C099B,
  danger:#B71C1C,
  info:#304FFE,
  warning:#E65100,
  success:#388E3C,

);

// .bg-primary {
  //   background-color: var(--bs-primary);
  // // }
  .nav-link:hover{
    color : white;
  }
  .nav-link{
    color: #101003
  }

  .nav-tabs :active{
    background-color: #101003
  }
  
  .tab-Header {
   background-color:  #8e908e !important;
  
  }
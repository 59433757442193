* {
    //primary Header
    --primary-head-bg: #fff;
    --primary-head-height: 60px;
    --primary-head-brand-width: 230px;
    --primary-head-link-color: #000;
    --primary-head-link-active-color: #f85717;
    
}

@function responsiveFont($baseFontSize, $reduce) {
    @return calc($baseFontSize - $reduce);
}


.fr-2 {
     font-size: calc(var(--bs-body-font-size) - 0.2rem);
    // font-size: responsiveFont(var(--bs-body-font-size), 0.2rem);
}

// $theme-colors: (
// //   "primary":    pink,
// ) !default;